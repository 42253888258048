'use client';

import Image from 'next/image';
import Logo from '&/assets/logo.png';
import { Button } from '&/components/ui/button';
import { Label } from '&/components/ui/label';
import { Input } from '&/components/ui/input';
import { signIn } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { FormEvent, useEffect, useState } from 'react';
import { useToast } from '&/components/ui/use-toast';
import axios from '&/lib/axios';

export default function SignInScreen() {
  const searchParams = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (!toast) return;

    try {
      const email = localStorage.getItem('email');
      if (email) {
        setEmail(email);
      }
    } catch (error) {}

    const error = searchParams.get('error');
    if (error) {
      if (error === 'CredentialsSignin') {
        setPasswordError('Invalid password!');
      }
      if (error === 'PasswordNotAllowed') {
        setPasswordError('Password login has been disabled for this account!');
      }
      if (error === 'TooManyRequests') {
        toast({
          title: 'Too many requests. Try again later.',
          variant: 'destructive',
        });
      }
      if (error === 'AccessDenied') {
        toast({
          title: 'No registered account for this email!',
          variant: 'destructive',
        });
      }
    }
  }, [toast, searchParams]);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (!email) {
      setEmailError('Email is required.');
    }
    if (!password) {
      setPasswordError('Password is required.');
    }
    if (!email || !password) {
      return;
    }

    setIsLoading(true);
    signIn('credentials', {
      username: email,
      password: password,
      callbackUrl: searchParams.get('callbackUrl') || '/',
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        toast({
          title: 'Something gone wrong while signing in. Try again.',
          variant: 'destructive',
        });
      });
  }

  function onClickResetPassword(e: FormEvent) {
    e.preventDefault();
    if (!email) {
      setEmailError('Email is required.');
    }
    if (!email) {
      return;
    }

    setIsLoading(true);
    axios
      .post('/users/api/request-reset-password/', { email })
      .then(() => {
        toast({
          title: 'Reset password link has been sent to your email.',
          variant: 'success',
        });
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch(() => {
        setIsLoading(false);
        toast({
          title:
            'Something went wrong while sending reset password link. Try again.',
          variant: 'destructive',
        });
      });
  }

  return (
    <div className="w-full min-h-screen bg-muted">
      <div className="max-w-screen-2xl mx-auto">
        <div className="p-8">
          <a href="https://www.meetcampfire.com">
            <Image className="h-8 w-auto" src={Logo} alt="Campfire logo" />
          </a>
        </div>
        <div className="my-16 mx-auto max-w-md flex flex-col gap-4">
          <h3>Welcome Back!</h3>
          <div className="border rounded p-4 shadow flex flex-col gap-4 bg-white">
            <form className="flex flex-col gap-4" onSubmit={onSubmit}>
              <div className="flex flex-col gap-2">
                <Label htmlFor="input-email">Email</Label>
                <Input
                  id="input-email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    const value = e.target.value;
                    try {
                      localStorage.setItem('email', value);
                    } catch (error) {}
                    setEmailError(null);
                    setEmail(value);
                  }}
                />
                {emailError && (
                  <div className="text-sm text-red-500">{emailError}</div>
                )}
              </div>
              {!isResettingPassword ? (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <Label htmlFor="input-password">Password</Label>
                      <a
                        className="text-sm font-semibold text-foreground cursor-pointer"
                        onClick={() => setIsResettingPassword(true)}
                      >
                        Forgot password?
                      </a>
                    </div>
                    <Input
                      id="input-password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(null);
                      }}
                    />
                    {passwordError && (
                      <div className="text-sm text-red-500">
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <Button>Sign in</Button>
                </>
              ) : (
                <>
                  <Button disabled={isLoading} onClick={onClickResetPassword}>
                    Reset Password
                  </Button>
                  <Button
                    variant={'outline'}
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsResettingPassword(false);
                    }}
                  >
                    Return to Sign In
                  </Button>
                </>
              )}
            </form>

            {!isResettingPassword && (
              <>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t"></span>
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-white px-2 text-gray-400">Or</span>
                  </div>
                </div>

                <Button
                  onClick={() => signIn('google')}
                  variant={'outline'}
                  className="flex items-center justify-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 533.5 544.3"
                    aria-hidden="true"
                  >
                    <path
                      fill="#4285f4"
                      d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                    ></path>
                    <path
                      fill="#34a853"
                      d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                    ></path>
                    <path
                      fill="#fbbc05"
                      d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                    ></path>
                    <path
                      fill="#ea4335"
                      d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                    ></path>
                  </svg>
                  Sign In With Google
                </Button>
              </>
            )}
          </div>
          <div className="text-gray-400 text-center flex flex-col gap-2 my-4">
            <div>
              Copyright © {new Date().getFullYear()} Campfire Software Inc.
            </div>
            <div className="flex gap-2 mx-auto">
              <a href="https://meetcampfire.com/terms-and-conditions/">
                Terms & Conditions
              </a>
              <span>|</span>
              <a href="https://meetcampfire.com/privacy-policy/">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
